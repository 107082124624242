.people {
  
	padding: 0;
	margin: 0;
	@extend %flexbox;
	@include flex-flow(wrap);
	text-align: center;
	// li {
	// 	padding: 30px 20px;
	// 	// box-sizing: border-box;
	// 	// width: 100%;

	// 	@media #{$tablet} {
	// 		@include flex(1, 1, 50%);
	// 	}
	// 	@media #{$desktop} {
	// 		@include flex(1, 1, 33%);
	// 	}

    // }
	.personal-image {
		width: 250px;
		height: 250px;
		border-radius: 50px;
        margin: 0 auto;
        background-size:cover;
        background-position: center;
        background-repeat:no-repeat;
        margin: 0 auto;
	}

	.name {
		margin-top: 20px;
	}

	.position {
		color: #666;
		font-size: .8em;
    }
    p {
		color: #000;
		text-decoration: none;
		font-weight: normal;
        font-size: 1.3em;
        text-align: justify;
        text-justify: auto;
    }

	ul {
        text-decoration: none;
		font-weight: normal;
        font-size: 1.3em;
        text-align: justify;
		text-justify: auto;
	  }

	.timeimage {
		max-width: 300px;
		border-radius: 20px;
		background-size:cover;
		background-repeat:no-repeat;
		margin: 0 auto;
	}
}

.bibliography {

	ul{
		list-style: none;
	}

	li{
		list-style: none;

		@media #{$tablet} {
			@include flex(1, 1, 50%);
		}

		@media #{$desktop} {
		@include flex(1, 1, 33%);
		}
	}

}


// bootstrap insert:

.nav {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
  }
  .nav > li {
	position: relative;
	display: block;
  }
  .nav > li > a {
	position: relative;
	display: block;
	padding: 10px 15px;
  }
  .nav > li > a:hover,
  .nav > li > a:focus {
	text-decoration: none;
	background-color: #eeeeee;
  }
  .nav > li.disabled > a {
	color: #777777;
  }
  .nav > li.disabled > a:hover,
  .nav > li.disabled > a:focus {
	color: #777777;
	text-decoration: none;
	cursor: not-allowed;
	background-color: transparent;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
	background-color: #eeeeee;
	border-color: #337ab7;
  }
  .nav .nav-divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
  }
  .nav > li > a > img {
	max-width: none;
  }
  .nav-tabs {
	border-bottom: 1px solid #dddddd;
  }
  .nav-tabs > li {
	float: left;
	margin-bottom: -1px;
  }
  .nav-tabs > li > a {
	margin-right: 2px;
	line-height: 1.42857143;
	border: 1px solid transparent;
	border-radius: 4px 4px 0 0;
  }
  .nav-tabs > li > a:hover {
	border-color: #eeeeee #eeeeee #dddddd;
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
	color: #555555;
	cursor: default;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-bottom-color: transparent;
  }
  .nav-tabs.nav-justified {
	width: 100%;
	border-bottom: 0;
  }
  .nav-tabs.nav-justified > li {
	float: none;
  }
  .nav-tabs.nav-justified > li > a {
	margin-bottom: 5px;
	text-align: center;
  }
  .nav-tabs.nav-justified > .dropdown .dropdown-menu {
	top: auto;
	left: auto;
  }
  @media (min-width: 768px) {
	.nav-tabs.nav-justified > li {
	  display: table-cell;
	  width: 1%;
	}
	.nav-tabs.nav-justified > li > a {
	  margin-bottom: 0;
	}
  }
  .nav-tabs.nav-justified > li > a {
	margin-right: 0;
	border-radius: 4px;
  }
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
	border: 1px solid #dddddd;
  }
  @media (min-width: 768px) {
	.nav-tabs.nav-justified > li > a {
	  border-bottom: 1px solid #dddddd;
	  border-radius: 4px 4px 0 0;
	}
	.nav-tabs.nav-justified > .active > a,
	.nav-tabs.nav-justified > .active > a:hover,
	.nav-tabs.nav-justified > .active > a:focus {
	  border-bottom-color: #ffffff;
	}
  }
  .nav-pills > li {
	float: left;
  }
  .nav-pills > li > a {
	border-radius: 4px;
  }
  .nav-pills > li + li {
	margin-left: 2px;
  }
  .nav-pills > li.active > a,
  .nav-pills > li.active > a:hover,
  .nav-pills > li.active > a:focus {
	color: #ffffff;
	background-color: #337ab7;
  }
  .nav-stacked > li {
	float: none;
  }
  .nav-stacked > li + li {
	margin-top: 2px;
	margin-left: 0;
  }
  .nav-justified {
	width: 100%;
  }
  .nav-justified > li {
	float: none;
  }
  .nav-justified > li > a {
	margin-bottom: 5px;
	text-align: center;
  }
  .nav-justified > .dropdown .dropdown-menu {
	top: auto;
	left: auto;
  }
  @media (min-width: 768px) {
	.nav-justified > li {
	  display: table-cell;
	  width: 1%;
	}
	.nav-justified > li > a {
	  margin-bottom: 0;
	}
  }
  .nav-tabs-justified {
	border-bottom: 0;
  }
  .nav-tabs-justified > li > a {
	margin-right: 0;
	border-radius: 4px;
  }
  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
	border: 1px solid #dddddd;
  }
  @media (min-width: 768px) {
	.nav-tabs-justified > li > a {
	  border-bottom: 1px solid #dddddd;
	  border-radius: 4px 4px 0 0;
	}
	.nav-tabs-justified > .active > a,
	.nav-tabs-justified > .active > a:hover,
	.nav-tabs-justified > .active > a:focus {
	  border-bottom-color: #ffffff;
	}
  }
  .tab-content > .tab-pane {
	display: none;
  }
  .tab-content > .active {
	display: block;
  }
  .nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
  }
  .clearfix:before,
  .clearfix:after,
  .nav:before,
  .nav:after {
	display: table;
	content: " ";
  }
  .clearfix:after,
  .nav:after {
	clear: both;
  }

  .collapse {
	display: none;
  }
  .collapse.in {
	display: block;
  }
  tr.collapse.in {
	display: table-row;
  }
  tbody.collapse.in {
	display: table-row-group;
  }
  .collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-property: height, visibility;
	-o-transition-property: height, visibility;
	transition-property: height, visibility;
	-webkit-transition-duration: 0.35s;
	-o-transition-duration: 0.35s;
	transition-duration: 0.35s;
	-webkit-transition-timing-function: ease;
	-o-transition-timing-function: ease;
	transition-timing-function: ease;
  }
  
