html {
	background: #2b2b40;
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Rubik', sans-serif;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;

}

a {
	color: #00a4ca;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	max-width: 100%;
}

.center-image
{
    margin: 0 auto;
    display: block;
}

h1 strong, h2 strong {
	font-weight: 700;
}

h1 {
	font-weight: 700;
	margin: 0;
	word-break: break-word;
	font-size: 2.5em;
	@media #{$desktop} {
		font-size: 4em;
	};
}

h2 {
	font-weight: 300;
	font-size: 3em;
	margin: 0 0 30px 0;
	line-height: 1.2em;
}

h3 {
	margin: 20px 0 10px 0;
}

h2 a {
	color: #000;

	&:hover {
		text-decoration: none;
	}
}

p, address, ul {
	font-size: 1.38em;
	margin-bottom: 50px;
	font-weight: 600;
	line-height: 1.6em;
	font-family: 'Lato', sans-serif;
	text-align: justify;
}

.personal-image {
	width: 250px;
	height: 250px;
	border-radius: 50px;
	margin: 0 auto;
	background-size:cover;
	background-position: center;
	background-repeat:no-repeat;
	margin: 0 auto;
}

.project-icon {
	width: 250px;
	height: 250px;
	border-radius: 50px;
	margin: 0 auto;
	background-size:contain;
	background-position: center;
	background-repeat:no-repeat;
	margin: 0 auto;
}