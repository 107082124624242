.blog-posts {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 1em;

	li {
		padding: 50px 0;
	}

	.blog-post {
		padding: 18px 0;

		&:first-child {
			padding-top: 50px;
		}

		.post-details {
			padding-top: 15px;

		}
	}
}

.blog-post {

	.author {
		padding: 30px 0 0 0;
		border: 1px solid #eee;
		margin: 30px 0;
		font-size: .8em;

		.square-image {
			width: 125px;
			height: 125px;
		}
		.blurb {
			text-align: center;
		}
	}
	h3 {
		margin: 0;
		a {
			color: #000;
			text-decoration: none;
			font-weight: normal;
			font-size: 1.3em;
		}
	}

	h2 {
		text-align: left;
		font-size: 2.6em;
		margin: 0;
	}

	.post-details {
		border-bottom: 1px solid #eee;
		font-size: .9em;
		margin: 0;
		padding: 40px 0 0 0;

		.blog-filter {
			display: inline-block;
			text-align: left;

			a {
				position: relative;
				top: -5px;
			}
		}

		a {
			text-decoration: none;
		}

		.post-date {
			float: right;
			font-size: 1.2em;

		}

		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.post-content {
		padding: 0 0 40px 0;

		.button {
			margin: 30px 0 0 0;
		}
	}
}

.pagination {
	text-align: center;
}

.blog-filter {
	text-align: center;
	a {
		background: #eee;
    padding: 5px 5px;
    font-size: 1.2em;
    border-radius: 5px;
    color: #888;
		transition: .2s ease-in-out;

		&:hover {
			color: #555;
			text-decoration: none;
		}
	}
}

.blog-filter.cross a {
	padding-right: 8px;

	&:after {
		content: "x";
		font-size: .5em;
		position: relative;
		bottom: 4px;
		right: -3px;
	}
}

.change-category {
	margin: 0;
	padding: 30px 0;
}

.blog-navigation {
	font-size: 18px;
	display: block;
	width: 100%;
	overflow: hidden;
	a {
		display: block;
		width: 50%;
		float: left;
		margin: 1em 0;

	}
	.prev{
		text-align: center;

	}
	.next {
		text-align: center;
	}
}

.pagination {
	padding: 30px
}
